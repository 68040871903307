import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

export default () => (
  <StaticQuery
    query=
      {graphql`{
        allWordpressWpApiMenusMenusItems(filter: {slug:{eq:"footer-column-2"}}) {
          nodes {
            id
            name
            slug
            items {
              type_label
              title
              object_slug
              url
              path
              target
            }
          }
        }
      }`}
    render={data => (
      <ul>{data.allWordpressWpApiMenusMenusItems.nodes[0].items.map((item) =>
        <li key={item.type_label + item.object_slug}>
          {item.target ?
            <a href={item.url} target={item.target} rel="noopener noreferrer">
              {item.title}
            </a>
            :
            <Link activeClassName="active" to={'/' + item.path.replace('category/', '')}>
              {item.title}
            </Link>
          }
        </li>
      )}</ul>
    )}
  />
);
