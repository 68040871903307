import React from 'react';
import PropTypes from 'prop-types';
import IcomoonIcon from './IcomoonIcon';
import styled from '@emotion/styled';
import { color } from '../../utils/style';

const TextLink = styled('a')`
  svg {
    margin-left: 10px;
  }
  &.left-icon {
    svg {
      margin-right: 8px;
      margin-left: 0;
    }
  }
`;

const ButtonLink = ({ to, text, external, className, iconName, iconLeft, iconSize, customHandler, ariaText, iconColor }) => {
  const href = (!external && to !=='/') ? '/' + to : to;
  const classes = iconLeft ? className + ' left-icon' : className;
  const target = external ? {target: '_blank', rel: 'noopener noreferrer' } : '';
  return (
  <TextLink className={classes} href={href} onClick={customHandler} {...target} aria-label={ariaText}>
    { iconName && iconLeft &&
    <IcomoonIcon icon={iconName} color={iconColor ? iconColor : color.black} size={iconSize}/>
    }
    <span>{text}</span>
    { iconName && !iconLeft &&
      <IcomoonIcon icon={iconName} color={iconColor ? iconColor : color.black} size={iconSize}/>
    }
  </TextLink>
)};

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
  external: PropTypes.bool,
  className: PropTypes.string,
  iconName: PropTypes.string,
  iconLeft: PropTypes.bool,
  iconSize: PropTypes.number,
  customHandler: PropTypes.func,
  ariaText: PropTypes.string,
  iconColor: PropTypes.string,
};

// Specifies the default values for props:
ButtonLink.defaultProps = {
  external: false,
  className: 'button',
  iconName: 'arrow-right',
  iconLeft: false,
  iconSize: 18
};

export default ButtonLink;