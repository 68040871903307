import React from 'react';
const Paypal = ({ width = '100', className = '', height = '48', viewBox='0 0 100 48' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="#FFF" fillRule="evenodd">
      <g opacity=".8">
        <path d="M29.888 17.079c1.743 0 3.35.945 3.13 3.301-.266 2.8-1.766 4.35-4.133 4.356h-2.068c-.297 0-.441.243-.518.74l-.4 2.543c-.06.384-.258.573-.548.573h-1.924c-.307 0-.414-.196-.346-.635l1.588-10.19c.079-.502.267-.688.61-.688h4.609zm-3.133 5.456h1.566c.98-.038 1.631-.716 1.696-1.94.04-.756-.47-1.298-1.282-1.294l-1.475.007-.505 3.227zm11.494 5.277c.176-.16.355-.243.33-.045l-.063.472c-.032.246.065.377.295.377h1.709c.288 0 .428-.116.499-.561l1.053-6.611c.053-.332-.028-.495-.28-.495h-1.88c-.17 0-.252.095-.296.354l-.07.406c-.035.212-.133.25-.223.036-.319-.754-1.132-1.093-2.267-1.066-2.636.054-4.413 2.056-4.604 4.621-.147 1.984 1.275 3.543 3.15 3.543 1.36 0 1.967-.4 2.653-1.027l-.006-.004zm-1.432-1.017c-1.134 0-1.925-.906-1.761-2.015.164-1.11 1.225-2.015 2.36-2.015 1.135 0 1.925.906 1.761 2.015-.164 1.11-1.224 2.015-2.36 2.015zm8.6-5.867h-1.733c-.357 0-.503.267-.39.595l2.153 6.301-2.111 2.999c-.177.25-.04.478.21.478h1.947a.596.596 0 00.579-.284l6.619-9.493c.204-.292.108-.599-.227-.599H50.62c-.316 0-.443.126-.624.388l-2.76 4-1.233-4.009c-.072-.243-.252-.376-.584-.376h-.002z" opacity=".6"/>
        <path d="M59.576 17.079c1.743 0 3.35.945 3.13 3.301-.266 2.8-1.766 4.35-4.133 4.356h-2.066c-.298 0-.442.243-.519.74l-.4 2.543c-.06.384-.257.573-.548.573h-1.924c-.307 0-.413-.196-.345-.635l1.59-10.193c.079-.501.267-.688.61-.688h4.605v.003zm-3.133 5.456h1.566c.98-.038 1.631-.716 1.696-1.94.04-.756-.47-1.298-1.282-1.294l-1.475.007-.505 3.227zm11.494 5.277c.176-.16.355-.243.33-.045l-.063.472c-.032.246.065.377.295.377h1.709c.288 0 .428-.116.499-.561l1.053-6.611c.053-.332-.028-.495-.28-.495h-1.877c-.17 0-.252.095-.296.354l-.07.406c-.036.212-.133.25-.224.036-.318-.754-1.132-1.093-2.266-1.066-2.636.054-4.414 2.056-4.604 4.621-.147 1.984 1.274 3.543 3.149 3.543 1.36 0 1.968-.4 2.653-1.027l-.008-.004zm-1.43-1.017c-1.135 0-1.926-.906-1.762-2.015.164-1.11 1.226-2.015 2.36-2.015 1.135 0 1.926.906 1.762 2.015-.164 1.11-1.226 2.015-2.36 2.015zm7.884 1.832h-1.974a.233.233 0 01-.241-.267l1.733-10.981a.34.34 0 01.328-.267h1.974a.233.233 0 01.241.267L74.719 28.36a.34.34 0 01-.328.27v-.003z"/>
      </g>
    </g>
  </svg>;

  export default Paypal;