import React, { useState } from "react";
import styled from "@emotion/styled";
import IcomoonIcon from '../shared/IcomoonIcon';
import { color } from "../../utils/style";

const Wrap = styled('div')`
  .live-chat-btn {
    bottom: 10px;
    position: fixed;
    right: 10px;
    background: ${color.gold};
    box-shadow: ${color.goldButtonShadow};
    padding: 5px 30px;
    font-size: 1rem;
    font-weight: bold;
    line-height: 35px;
    color: #000;
    cursor: pointer;
    outline:none;
    border:none;
    border-radius: 25px;
    text-transform: uppercase;
    svg {
      margin-right: 10px;
    }
  }
    
  &.open {
    bottom: 0;
    .live-chat-btn {
      display: none;
    }
  }
  
`;

const RespondioChat = () => {
  const CHAT_SCRIPT_URL = "https://cdn.respond.io/webchat/widget/widget.js?cId=3e3e44cc1f52688b8d0a9e227eb8559";
  const [chatLoaded, setChatLoaded] = useState(false);

  const showHideChat = (url) => {
    const head = document.head;
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = "respondio__widget";

    const chatScript = document.getElementById("respondio__widget");
    if (chatScript === null) {
      head.appendChild(script);
      setChatLoaded(true);
    }
  }
  return (
    <Wrap className={chatLoaded ? "open" : "closed"}>
      <button className="live-chat-btn" onClick={() => showHideChat(CHAT_SCRIPT_URL)}>
        <IcomoonIcon icon="chat-2" color={color.trueBlack} size={16}/>
        <span>แชทสด</span>
      </button>
    </Wrap>
  );
};

export default RespondioChat;
