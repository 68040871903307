import React from 'react';
import { color } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';


const MobileContactLinkRespondio = () => {
  const CHAT_SCRIPT_URL = "https://cdn.respond.io/webchat/widget/widget.js?cId=3e3e44cc1f52688b8d0a9e227eb8559";

  const showHideChat = () => {
    const head = document.head;
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = CHAT_SCRIPT_URL;
    script.id = "respondio__widget";

    const chatScript = document.getElementById("respondio__widget");
    if (chatScript === null) {
      head.appendChild(script);
    }
  }
  return (
    <button className="live-chat" id="live-chat" aria-label="live-chat" onClick={() => showHideChat()}>
      <IcomoonIcon className="contact-menu-icon" icon="chat-new" color={color.red} size={30}/>
    </button>
  );
};

export default MobileContactLinkRespondio;
