import React from 'react';

const Youtube = ({width = '30', className = '', height = '24', viewBox = '0 0 30 24'}) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#youtube_filter0_dd)">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M20.353 5.52H9.647a3.803 3.803 0 00-3.803 3.804v5.352c0 2.1 1.703 3.803 3.803 3.803h10.706c2.1 0 3.803-1.703 3.803-3.803V9.324c0-2.1-1.702-3.803-3.803-3.803zm-2.572 6.74l-5.008 2.389a.2.2 0 01-.287-.182V9.541c0-.15.158-.247.292-.179l5.007 2.538a.201.201 0 01-.004.36z"
            fill="#fff"/>
      <path
        d="M20.353 5.27a.25.25 0 000 .5v-.5zm-7.58 9.379l-.107-.226.107.226zm.005-5.287l-.114.223.114-.223zm5.007 2.538l-.113.223.113-.223zm2.568-6.63H9.647v.5h10.706v-.5zm-10.706 0a4.053 4.053 0 00-4.053 4.054h.5a3.553 3.553 0 013.553-3.553v-.5zM5.594 9.325v5.352h.5V9.324h-.5zm0 5.352a4.053 4.053 0 004.053 4.053v-.5a3.553 3.553 0 01-3.553-3.553h-.5zm4.053 4.053h10.706v-.5H9.647v.5zm10.706 0a4.053 4.053 0 004.053-4.053h-.5a3.553 3.553 0 01-3.553 3.553v.5zm4.053-4.053V9.324h-.5v5.352h.5zm0-5.352a4.053 4.053 0 00-4.053-4.053v.5a3.553 3.553 0 013.553 3.553h.5zm-6.733 2.71l-5.007 2.389.215.451 5.008-2.388-.216-.451zm-5.007 2.389a.049.049 0 01.07.044h-.5c0 .332.346.55.645.407l-.215-.451zm.07.044V9.541h-.5v4.926h.5zm0-4.926c0 .037-.039.06-.072.044l.227-.446a.451.451 0 00-.655.402h.5zm-.071.044l5.007 2.538.226-.446-5.007-2.538-.226.446zm5.007 2.538a.049.049 0 01.001-.088l.215.451a.451.451 0 00.01-.81l-.226.447z"
        fill="#FFAFAF"/>
    </g>
    <defs>
      <filter id="youtube_filter0_dd" x=".594" y=".271" width="28.813" height="23.459" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
        <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
;

export default Youtube;