import React from 'react';

const GooglePlus = ({ width='36', className='', height='26', viewBox='0 0 36 26' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#google_plus_filter0_dd)">
      <path
        d="M20.703 13.174c0 4.288-2.875 7.326-7.203 7.326A7.498 7.498 0 016 13c0-4.144 3.356-7.5 7.5-7.5 2.026 0 3.714.737 5.025 1.964l-2.036 1.954c-.553-.532-1.525-1.156-2.988-1.156-2.558 0-4.645 2.119-4.645 4.738s2.088 4.737 4.645 4.737c2.968 0 4.083-2.138 4.257-3.233h-4.257v-2.578h7.08c.071.378.122.757.122 1.248zM25.636 10h2.187v2.177H30v2.187h-2.177v2.177h-2.187v-2.177h-2.177v-2.187h2.177V10z"
        fill="#fff"/>
      <path
        d="M20.703 13.174c0 4.288-2.875 7.326-7.203 7.326A7.498 7.498 0 016 13c0-4.144 3.356-7.5 7.5-7.5 2.026 0 3.714.737 5.025 1.964l-2.036 1.954c-.553-.532-1.525-1.156-2.988-1.156-2.558 0-4.645 2.119-4.645 4.738s2.088 4.737 4.645 4.737c2.968 0 4.083-2.138 4.257-3.233h-4.257v-2.578h7.08c.071.378.122.757.122 1.248zM25.636 10h2.187v2.177H30v2.187h-2.177v2.177h-2.187v-2.177h-2.177v-2.187h2.177V10z"
        stroke="#FFAFAF" strokeWidth=".5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <filter id="google_plus_filter0_dd" x=".75" y=".25" width="34.5" height="25.5" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
        <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
;

export default GooglePlus;