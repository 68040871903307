import React from "react"
import styled from "@emotion/styled"

const SvgWrap = styled("div")`
  margin-top: 8px;
`

const Logo = ({
  width = "240",
  className = "",
  height = "35",
  viewBox = "0 0 240 35",
}) => {
  return (
    <SvgWrap>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
      >
        <defs>
          <linearGradient
            id="a-main-logo"
            x1="49.595%"
            x2="49.595%"
            y1="99.79%"
            y2=".065%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="b-main-logo"
            x1="49.595%"
            x2="49.595%"
            y1="100.2%"
            y2="-.345%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="c-main-logo"
            x1="49.807%"
            x2="49.807%"
            y1="100.383%"
            y2=".789%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="d-main-logo"
            x1="49.807%"
            x2="49.807%"
            y1="100.779%"
            y2=".392%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="e-main-logo"
            x1="49.782%"
            x2="49.782%"
            y1="100.392%"
            y2=".064%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="f-main-logo"
            x1="49.841%"
            x2="49.841%"
            y1="100.95%"
            y2="-.342%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient id="g-main-logo" x1="49.82%" x2="49.82%" y1="99.79%" y2=".065%">
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="h-main-logo"
            x1="49.82%"
            x2="49.82%"
            y1="100.2%"
            y2="-.345%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="i-main-logo"
            x1="49.662%"
            x2="49.662%"
            y1="99.83%"
            y2=".169%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="j-main-logo"
            x1="49.662%"
            x2="49.662%"
            y1="100.233%"
            y2="-.234%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="k-main-logo"
            x1="49.438%"
            x2="49.438%"
            y1="99.787%"
            y2="-1.601%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="l-main-logo"
            x1="49.438%"
            x2="49.438%"
            y1="100.203%"
            y2="-2.018%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="m-main-logo"
            x1="49.548%"
            x2="49.548%"
            y1="100.605%"
            y2="1.69%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="n-main-logo"
            x1="49.548%"
            x2="49.548%"
            y1="101.011%"
            y2="1.284%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="o-main-logo"
            x1="51.148%"
            x2="51.148%"
            y1="99.198%"
            y2=".243%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="p-main-logo"
            x1="51.148%"
            x2="51.148%"
            y1="99.602%"
            y2="-.16%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="q-main-logo"
            x1="48.973%"
            x2="48.973%"
            y1="100.005%"
            y2="-1.383%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="r-main-logo"
            x1="48.973%"
            x2="48.973%"
            y1="100.422%"
            y2="-1.799%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="s-main-logo"
            x1="49.802%"
            x2="49.802%"
            y1="100.61%"
            y2="-.54%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="t-main-logo"
            x1="49.861%"
            x2="49.861%"
            y1="101.173%"
            y2="-.95%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="u-main-logo"
            x1="49.399%"
            x2="49.399%"
            y1="100.005%"
            y2="-1.383%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="v-main-logo"
            x1="49.399%"
            x2="49.399%"
            y1="100.422%"
            y2="-1.799%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="w-main-logo"
            x1="49.313%"
            x2="49.313%"
            y1="99.797%"
            y2=".203%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="x-main-logo"
            x1="49.353%"
            x2="49.353%"
            y1="100.194%"
            y2="-.194%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="y-main-logo"
            x1="50.367%"
            x2="50.367%"
            y1="100.183%"
            y2=".101%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="z-main-logo"
            x1="50.426%"
            x2="50.426%"
            y1="100.744%"
            y2="-.449%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="A-main-logo"
            x1="50.569%"
            x2="50.569%"
            y1="99.708%"
            y2="-.377%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="B-main-logo"
            x1="50.569%"
            x2="50.569%"
            y1="100.753%"
            y2="-1.059%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="C-main-logo"
            x1="51.794%"
            x2="51.794%"
            y1="99.711%"
            y2="-.23%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="D-main-logo"
            x1="51.794%"
            x2="51.794%"
            y1="100.854%"
            y2="-.905%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="E-main-logo"
            x1="51.148%"
            x2="51.148%"
            y1="100.066%"
            y2=".056%"
          >
            <stop offset="5.614%" stopColor="#E4B622" />
            <stop offset="5.918%" stopColor="#E4B622" />
            <stop offset="7.137%" stopColor="#E4B622" />
            <stop offset="8.573%" stopColor="#E4B622" />
            <stop offset="10.34%" stopColor="#E4B622" />
            <stop offset="12.8%" stopColor="#E4B622" />
            <stop offset="19.45%" stopColor="#E4B622" />
            <stop offset="19.56%" stopColor="#E4B622" />
            <stop offset="23.68%" stopColor="#E4B622" />
            <stop offset="28%" stopColor="#E4B622" />
            <stop offset="32.85%" stopColor="#E4B622" />
            <stop offset="34.85%" stopColor="#E4B622" />
            <stop offset="37.35%" stopColor="#E4B622" />
            <stop offset="38.79%" stopColor="#E4B622" />
            <stop offset="49.76%" stopColor="#E4B622" />
            <stop offset="63.73%" stopColor="#E4B622" />
            <stop offset="67.63%" stopColor="#E4B622" />
            <stop offset="76.14%" stopColor="#E4B622" />
            <stop offset="88.56%" stopColor="#FCF8EC" />
            <stop offset="90.22%" stopColor="#FFF" />
          </linearGradient>
          <linearGradient
            id="F-main-logo"
            x1="51.148%"
            x2="51.148%"
            y1="100.43%"
            y2="-.259%"
          >
            <stop offset="4.097%" stopColor="#190E10" />
            <stop offset="5.442%" stopColor="#2D211C" />
            <stop offset="8.286%" stopColor="#513D29" />
            <stop offset="11.29%" stopColor="#74562F" />
            <stop offset="14.44%" stopColor="#966E32" />
            <stop offset="17.78%" stopColor="#B38332" />
            <stop offset="21.4%" stopColor="#E4B622" />
            <stop offset="25.5%" stopColor="#E4B622" />
            <stop offset="31.12%" stopColor="#E4B622" />
            <stop offset="71.53%" stopColor="#5C100D" />
            <stop offset="74.68%" stopColor="#611811" />
            <stop offset="79.55%" stopColor="#702B19" />
            <stop offset="85.53%" stopColor="#894927" />
            <stop offset="92.39%" stopColor="#B2753A" />
            <stop offset="99.91%" stopColor="#E4B622" />
            <stop offset="100%" stopColor="#E4B622" />
          </linearGradient>
          <linearGradient
            id="G-main-logo"
            x1="49.846%"
            x2="49.846%"
            y1="100.067%"
            y2="-.07%"
          >
            <stop offset="0%" stopColor="#DC3627" />
            <stop offset="20.06%" stopColor="#71241F" />
            <stop offset="30.46%" stopColor="#491514" />
            <stop offset="32.02%" stopColor="#4D1A17" />
            <stop offset="33.98%" stopColor="#59271D" />
            <stop offset="36.16%" stopColor="#6E3D26" />
            <stop offset="38.49%" stopColor="#905D31" />
            <stop offset="40.92%" stopColor="#C38E3C" />
            <stop offset="42.82%" stopColor="#FFC83F" />
            <stop offset="45.89%" stopColor="#FFCC50" />
            <stop offset="51.86%" stopColor="#FFD576" />
            <stop offset="60.14%" stopColor="#FFE4AB" />
            <stop offset="70.33%" stopColor="#FFFDF8" />
            <stop offset="70.83%" stopColor="#FFF" />
            <stop offset="88.96%" stopColor="#FFF" />
            <stop offset="91.94%" stopColor="#FFF" />
          </linearGradient>
        </defs>
        <g fillRule="nonzero" fill="none">
          <g strokeWidth=".1">
            <path
              fill="url(#a-main-logo)"
              stroke="url(#b-main-logo)"
              d="M18.201 20.617h-5.637v-.483c.805 0 1.127-.322 1.127-1.288v-7.088H5.96v7.088c0 .966.16 1.288.966 1.288v.483H1.45v-.483c.805 0 1.127-.322 1.127-1.288V2.738c0-.966-.322-1.288-1.127-1.288V.966h5.637v.484c-.805 0-.966.322-.966 1.288v5.96h7.57v-5.96c0-.966-.16-1.288-1.127-1.288V.966H18.2v.484c-.805 0-1.127.322-1.127 1.288v16.108c0 .966.322 1.288 1.127 1.288v.483z"
              transform="translate(0 4.832)"
            />
            <path
              fill="url(#c-main-logo)"
              stroke="url(#d-main-logo)"
              d="M28.671 20.779c-4.993 0-8.537-3.705-8.537-10.148 0-5.96 3.544-10.148 8.537-10.148s8.537 4.188 8.537 10.148c0 6.12-3.221 10.148-8.537 10.148zm0-17.074c-3.06 0-4.993 2.899-4.993 7.087 0 3.705 1.772 7.087 4.993 7.087 3.222 0 4.993-3.382 4.993-7.087 0-4.027-1.771-7.087-4.993-7.087z"
              transform="translate(0 4.832)"
            />
            <path
              fill="url(#e-main-logo)"
              stroke="url(#f-main-logo)"
              d="M52.51 16.43l-1.127 4.349c-.323-.162-.806-.323-1.611-.323H39.14v-.483c.805 0 1.127-.16 1.127-1.288V2.416c0-.805-.483-.966-1.127-.966V.966h5.638v.484c-.645 0-1.128 0-1.128 1.127v14.98h6.443c.805 0 1.289-.161 1.772-1.289l.644.162z"
              transform="translate(0 4.832)"
            />
            <path
              fill="url(#g-main-logo)"
              stroke="url(#h-main-logo)"
              d="M59.597 20.617H53.96v-.483c.805 0 1.127-.322 1.127-1.288V2.738c0-.966-.16-1.288-1.127-1.288V.966h5.637v.484c-.805 0-.966.322-.966 1.288v16.108c0 .966.16 1.288.966 1.288v.483z"
              transform="translate(0 4.832)"
            />
            <path
              fill="url(#i-main-logo)"
              stroke="url(#j-main-logo)"
              d="M67.168 20.779c-1.611 0-4.027 0-5.477-.162v-.483c.806 0 1.128-.322 1.128-1.127V2.577c0-.966-.322-1.127-1.128-1.127V.966c1.611-.16 3.866-.16 5.638-.16 7.57 0 10.63 3.543 10.63 9.664 0 5.637-2.738 10.309-10.791 10.309zm.805-17.074h-1.772v14.013h1.772c4.188 0 6.282-2.9 6.282-7.248 0-4.188-2.255-6.765-6.282-6.765z"
              transform="translate(0 4.832)"
            />
            <path
              fill="url(#k-main-logo)"
              stroke="url(#l-main-logo)"
              d="M94.228 20.617h-5.476v-.483c.483 0 .805-.16.805-.805 0-.161 0-.483-.161-.644l-.483-1.45a16.63 16.63 0 01-3.222.322c-1.288 0-2.416-.161-3.221-.322l-.483 1.61c-.161.323-.161.484-.161.645 0 .483.322.644.966.644v.483h-5.315v-.483c.483 0 .805-.322 1.127-1.288L83.436 3.22c.161-.322.322-.805.322-.966 0-.322-.16-.483-.483-.483v-.483h5.155v.483c-.484 0-.484.483-.484.644 0 .161.161.805.322 1.128l4.994 15.463c.322 1.127.644 1.45 1.127 1.45v.16h-.16zm-8.537-14.98h0l-2.255 8.537c.645.162 1.611.323 2.416.323.806 0 1.772-.161 2.416-.323l-2.577-8.536z"
              transform="translate(0 4.832)"
            />
            <path
              fill="url(#m-main-logo)"
              stroke="url(#n-main-logo)"
              d="M107.597 1.45c-.805 0-.966.16-1.45 1.127l-4.993 9.665v6.443c0 .966.323 1.288 1.128 1.288v.483h-5.638v-.483c.806 0 1.128-.322 1.128-1.288v-6.443l-4.993-9.665c-.484-1.127-.645-1.288-1.45-1.288V.805h5.637v.484c-.483 0-.644.16-.644.322 0 .16.161.322.161.644l3.06 6.765h0l3.061-6.765c.161-.322.322-.644.322-.805 0-.161-.16-.322-.644-.322V.644h5.476v.806h-.16z"
              transform="translate(0 4.832)"
            />
          </g>
          <g strokeWidth=".1">
            <path
              fill="url(#o-main-logo)"
              stroke="url(#p-main-logo)"
              d="M7.087 13.047H5.154v5.638c0 .966.161 1.288.967 1.288v.483H.483v-.483c.806 0 1.128-.322 1.128-1.288V2.255c0-.805-.322-1.127-1.128-1.127V.644c1.772-.16 4.51-.16 6.443-.16 5.316 0 8.215 1.771 8.215 6.281.161 3.866-2.738 6.282-8.054 6.282zm.322-9.503c-.966 0-1.45 0-2.094.16v6.444c.645 0 1.289.16 2.094.16 3.06 0 4.51-1.288 4.51-3.382-.16-2.416-2.093-3.382-4.51-3.382z"
              transform="translate(146.577 4.832)"
            />
            <path
              fill="url(#q-main-logo)"
              stroke="url(#r-main-logo)"
              d="M30.604 20.295h-5.476v-.483c.483 0 .805-.161.805-.805 0-.161 0-.484-.161-.645l-.483-1.45a16.54 16.54 0 01-3.222.323c-1.288 0-2.416-.161-3.221-.322l-.484 1.61c-.16.323-.16.484-.16.645 0 .483.321.644.966.644v.483h-5.316v-.483c.484 0 .806-.322 1.128-1.289L19.812 2.9c.161-.322.322-.805.322-.966 0-.322-.16-.483-.483-.483V.966h5.154v.484c-.483 0-.483.483-.483.644 0 .161.161.805.322 1.127l4.994 15.464c.322 1.127.644 1.45 1.127 1.45v.16h-.161zm-8.698-14.98h0l-2.255 8.537c.644.161 1.61.322 2.416.322.805 0 1.772-.16 2.416-.322l-2.577-8.537z"
              transform="translate(146.577 4.832)"
            />
            <path
              fill="url(#s-main-logo)"
              stroke="url(#t-main-logo)"
              d="M45.262 16.107l-1.128 4.35c-.322-.162-.805-.323-1.61-.323H31.892v-.483c.805 0 1.127-.161 1.127-1.289V2.255c0-.805-.483-.966-1.127-.966V.805h5.637v.484c-.644 0-1.127 0-1.127 1.127v14.98h6.443c.805 0 1.288-.161 1.771-1.289h.645z"
              transform="translate(146.577 4.832)"
            />
            <path
              fill="url(#u-main-logo)"
              stroke="url(#v-main-logo)"
              d="M62.497 20.295H57.02v-.483c.483 0 .806-.161.806-.805 0-.161 0-.484-.162-.645l-.483-1.45a16.53 16.53 0 01-3.221.323 16.63 16.63 0 01-3.222-.322l-.483 1.61c-.161.323-.161.484-.161.645 0 .483.322.644.966.644v.483h-5.315v-.483c.483 0 .805-.322 1.127-1.289L51.705 2.9c.16-.322.322-.805.322-.966 0-.322-.161-.483-.483-.483V.966h5.154v.484c-.483 0-.483.483-.483.644 0 .161.16.805.322 1.127l4.993 15.464c.322 1.127.644 1.45 1.128 1.45v.16h-.161zm-8.698-14.98h0l-2.255 8.537c.644.161 1.61.322 2.416.322.805 0 1.772-.16 2.416-.322l-2.577-8.537z"
              transform="translate(146.577 4.832)"
            />
            <path
              fill="url(#w-main-logo)"
              stroke="url(#x-main-logo)"
              d="M77.154 15.302l-.805 4.993s-.483-.16-.966-.16c-.806 0-2.255.482-3.383.482-5.638 0-9.02-3.865-9.02-10.147C62.98 4.349 66.523.322 72 .322c1.128 0 2.738.322 3.383.322.483 0 .966-.16.966-.16l.805 4.67-.644.161c-.483-1.288-1.933-1.932-4.51-1.932-3.544 0-5.638 2.738-5.638 7.087 0 4.67 2.417 7.087 5.638 7.087 2.577 0 3.866-.966 4.51-2.416l.644.161z"
              transform="translate(146.577 4.832)"
            />
            <path
              fill="url(#y-main-logo)"
              stroke="url(#z-main-logo)"
              d="M92.94 16.268l-.967 4.188c-.322-.16-.966-.16-1.45-.16H79.087v-.484c.806 0 1.128-.322 1.128-1.289V2.416c0-.966-.161-1.288-1.128-1.288V.644h11.436c.484 0 .967-.16 1.29-.16l.966 4.187-.645.161c-.322-.644-.644-1.127-1.772-1.127h-6.604v4.349h6.443c.806 0 1.128-.161 1.128-.967h.483v4.832h-.483c0-.805-.322-.966-1.128-.966h-6.443v6.443h7.088c.966 0 1.288-.483 1.61-1.128h.484z"
              transform="translate(146.577 4.832)"
            />
          </g>
          <g strokeWidth=".15">
            <path
              fill="url(#A-main-logo)"
              stroke="url(#B-main-logo)"
              d="M8.376 19.329l-3.383-1.61v-.645c.484 0 .645-.483.645-1.128V9.342H2.899v5.96c0 .483.161 1.289.645 1.289v.644L0 15.625v-.484c.322 0 .644-.322.644-.805V2.738c0-.644-.16-.805-.644-.805V1.61h3.383v.322c-.484 0-.645.322-.645.805v4.994h2.739V2.738c0-.644-.162-.805-.645-.805V1.61h3.383v.322c-.483 0-.645.322-.645.805v13.37c0 .483.162 2.577.645 2.577v.644h.16z"
              transform="translate(119.195 9.664)"
            />
            <path
              fill="url(#C-main-logo)"
              stroke="url(#D-main-logo)"
              d="M12.886 12.08h-.644v4.027c0 .484.322.806.805.806v.322l-3.705 2.094v-.644c.484 0 .645-1.933.645-2.578V2.738c0-.644-.161-.966-.645-.966V1.61c.806 0 1.772-.161 3.06-.161 2.739 0 4.028 1.771 4.028 5.476.16 3.383-1.45 5.155-3.544 5.155zm-.161-8.859h-.483v7.088h.483c.805 0 1.61-.967 1.61-3.544 0-2.416-.644-3.544-1.61-3.544z"
              transform="translate(119.195 9.664)"
            />
          </g>
          <path
            fill="url(#E-main-logo)"
            stroke="url(#F-main-logo)"
            strokeWidth=".13"
            d="M135.785 1.61v2.9s-3.543-.483-5.798-.483V1.289s-1.933-.323-2.9-.161c0 0-2.094 0-2.9.16v2.739s-4.026.16-5.798.483v-2.9s-1.772.162-3.06.645v25.289l11.597 6.765 11.597-6.765V2.255c.323 0-1.45-.644-2.738-.644zm1.45 24.645l-9.987 5.638-9.986-5.638V9.342s10.147-1.288 19.973 0v16.913z"
          />
          <path
            fill="url(#G-main-logo)"
            d="M118.55 1.61v2.9c1.45-.322 5.799-.483 5.799-.483V1.289c.966-.161 2.9-.161 2.9-.161v7.731c-8.054 0-9.987.483-9.987.483v16.913l9.986 5.638v2.416l-11.597-6.765V2.255c1.128-.483 2.9-.644 2.9-.644zm17.235 0v2.9c-1.45-.322-5.798-.483-5.798-.483V1.289c-.967-.161-2.739-.161-2.739-.161v7.731c8.054 0 9.987.483 9.987.483v16.913l-9.987 5.638v2.416l11.598-6.765V2.255c-1.128-.483-3.06-.644-3.06-.644z"
          />
        </g>
      </svg>
    </SvgWrap>
  )
}

export default Logo
