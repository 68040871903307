import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { breakpoints } from '../../utils/style';
import ButtonLink from '../shared/ButtonLink';
import { LogoutButton } from '../Auth/Auth';
import StoreContext from '../shared/Context/StoreContext';

const HeaderContacts = styled('div')`
  flex-basis: 54%;
  text-align: right;
  padding-top: 7px;
  .header-search-wrapper {
    display: inline-block;
  }
  .header-contact-group {
    display: inline-block;
    vertical-align: top;
    .button {
      padding: 9px 23px 5px;
      margin: 0 25px;
      font-size: 1.1rem;
      text-align: left;
      display: inline-block;
      vertical-align: middle;
    }
    .green-button {
      width: 225px;
      height: 46px;
      text-align: center;
      float: right;
      margin: 0;
      span {
        font-size: 16px;
        font-weight: bold;
        display: inline-block;
        vertical-align: baseline;
      }
    }
    .register-button {
      padding: 0;
      height: 48px;
      width: 171px;
      text-align: center;
      span {
        display: inline-block;
        vertical-align: middle;
        line-height: 45px;
      }
    }
    .logout-button {
      height: auto;
      padding: 14px 23px;
      width: 178px;
      text-align: center;
      svg {
        margin-left: 7px;
        vertical-align: top !important;
      }
    }
  }
  .header-image-wrap,
  .button {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 20px;
  }
  .header-image-wrap .gatsby-image-wrapper {
    border-radius: 100%;
  }
  .popup-content {
    text-align: center;
  }

  @media (max-width: ${breakpoints.lg}) {
    flex-basis: 63%;
    .header-contact-group {
      .button {
        margin: 0 0 0 10px;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    display: none;
    .header-contact-group {
      display: none;
    }
    .header-search-wrapper {
      display: block;
      width: 100%;
      position: relative;
      .close-menu {
        position: absolute;
        top: 22px;
        right: 5px;
        background: none;
      }
    }
  }
`;

export default ({ children }) => {
  const value = useContext(StoreContext);
  const username = value.username;
  return (
    <HeaderContacts id="header-contacts-menu" className="header-contacts-menu">
      {children}
      <div className="header-contact-group">

        {username ? (
          <LogoutButton />
        ) : (
          <a
            className="button transparent-button register-button"
            href={'https://www.gclub-casino.com/register/'}
            target="_blank"
            rel="noreferrer noopener"
          >
            <span>เข้าสู่ระบบ</span>
          </a>
        )}

        <ButtonLink
          to={process.env.LINE_ID_EXT}
          text={process.env.LINE_ID_TEXT}
          external={true}
          className="button green-button"
          iconName="line"
          iconSize={30}
          ariaText="Line"
          iconLeft={true}
        />
      </div>
    </HeaderContacts>
  );
};
