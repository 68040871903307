import React from 'react';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../../utils/style';
import TopSearchIcon from '../../../icons/svg/TopSearch';

const El = styled('button')`
  display: none;
  width: 52px;
  height: 48px;
  background: ${color.transparentWhite1};
  border: none;
  border-radius: 24px;
  padding: 4px;
  padding-top: 0;
  &:active,
  &:focus {
    outline: none;
    opacity: 0.7;
  }
  @media (max-width: ${breakpoints.md}) {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 13px;
    padding-top: 3px;
    padding-right: 7px;
  }
  @media (max-width: ${breakpoints.sm}) {
    padding-top: 3px;
    right: 20px;
  }
  @media (max-width: ${breakpoints.xs}) {
    top: 13px;
    width: 46px;
    height: 46px;
    padding: 5px 11px 5px 6px;
    svg {
      max-width: 100%;
    }
  }
  @media (max-width: ${breakpoints.xxs}) {
    right: 15px
  }
`;

const TopSearch = () => {
  const handleClick = e => {
    e.preventDefault();
    const block = document.getElementById('header-contacts-menu');
    const layout = document.getElementById('all-content');
    const navWrap = document.getElementById('nav-wrap');
    layout.classList.toggle('inter-blocked');
    block.classList.toggle('open');
    navWrap.classList.toggle('search-open');
  };

  return (
    <El id="searchButton" aria-label="Search" onClick={e => handleClick(e)}>
      <TopSearchIcon/>
    </El>
  );
};

export default TopSearch;
