import React from 'react';

const Facebook = ({width = '21', className = '', height = '33', viewBox = '0 0 21 33'}) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#facebook_filter0_dd)" clipRule="evenodd">
      <path fillRule="evenodd"
            d="M14.88 16.508h-2.835v10.385H7.75V16.508H5.708v-3.65H7.75v-2.361c0-1.69.803-4.334 4.333-4.334l3.182.013V9.72h-2.309c-.378 0-.91.19-.91.995v2.148h3.21l-.376 3.646z"
            fill="#fff"/>
      <path
        d="M14.88 16.508h-2.835v10.385H7.75V16.508H5.708v-3.65H7.75v-2.361c0-1.69.803-4.334 4.333-4.334l3.182.013V9.72h-2.309c-.378 0-.91.19-.91.995v2.148h3.21l-.376 3.646z"
        stroke="#FFAFAF" strokeWidth=".5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <filter id="facebook_filter0_dd" x=".458" y=".913" width="20.057" height="31.23" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
        <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
;

export default Facebook;