import React from 'react';

const Twitter = ({width = '30', className = '', height = '26', viewBox = '0 0 30 26'}) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#twitter_filter0_dd)" clipRule="evenodd">
      <path fillRule="evenodd"
            d="M24.617 7.417a7.575 7.575 0 01-2.18.597 3.807 3.807 0 001.67-2.1 7.602 7.602 0 01-2.412.921 3.798 3.798 0 00-6.47 3.464A10.78 10.78 0 017.396 6.33a3.797 3.797 0 001.175 5.07 3.77 3.77 0 01-1.72-.476v.048a3.8 3.8 0 003.046 3.723 3.785 3.785 0 01-1.714.066A3.801 3.801 0 0011.73 17.4a7.619 7.619 0 01-5.622 1.572 10.74 10.74 0 005.82 1.707c6.985 0 10.804-5.786 10.804-10.805 0-.164-.003-.328-.01-.49a7.699 7.699 0 001.894-1.966z"
            fill="#fff"/>
      <path
        d="M24.617 7.417a7.575 7.575 0 01-2.18.597 3.807 3.807 0 001.67-2.1 7.602 7.602 0 01-2.412.921 3.798 3.798 0 00-6.47 3.464A10.78 10.78 0 017.396 6.33a3.797 3.797 0 001.175 5.07 3.77 3.77 0 01-1.72-.476v.048a3.8 3.8 0 003.046 3.723 3.785 3.785 0 01-1.714.066A3.801 3.801 0 0011.73 17.4a7.619 7.619 0 01-5.622 1.572 10.74 10.74 0 005.82 1.707c6.985 0 10.804-5.786 10.804-10.805 0-.164-.003-.328-.01-.49a7.699 7.699 0 001.894-1.966z"
        stroke="#FFAFAF" strokeWidth=".5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <filter id="twitter_filter0_dd" x=".859" y=".386" width="28.966" height="25.541" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
        <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
;

export default Twitter;