import React from 'react';
const Visa = ({ width = '100', className = '', height = '48', viewBox='0 0 100 48' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="#FFF" fillRule="evenodd">
      <g opacity=".8">
        <path d="M46.957 32.587h-3.945l2.466-16.295h3.945l-2.466 16.295m-7.263-16.295l-3.761 11.207-.445-2.413-1.327-7.327s-.16-1.467-1.872-1.467h-6.216l-.073.275s1.902.426 4.126 1.864l3.427 14.156h4.11l6.276-16.295h-4.245M66.38 26.823l2.073-6.099 1.166 6.099H66.38zm4.34 5.764h3.623l-3.158-16.295h-3.171c-1.465 0-1.821 1.214-1.821 1.214L60.31 32.587h4.111l.823-2.42h5.014l.463 2.42zM60.618 20.21l.563-3.5S59.444 16 57.633 16c-1.958 0-6.606.92-6.606 5.395 0 4.21 5.456 4.262 5.456 6.473 0 2.21-4.894 1.815-6.509.42l-.587 3.66s1.761.92 4.453.92 6.753-1.5 6.753-5.58c0-4.236-5.505-4.63-5.505-6.473 0-1.842 3.842-1.605 5.53-.605"/>
        <path d="M35.488 25.087l-1.327-7.328s-.16-1.467-1.872-1.467h-6.216l-.073.275s2.988.667 5.855 3.162c2.74 2.385 3.633 5.358 3.633 5.358"/>
      </g>
    </g>
  </svg>;

  export default Visa;