import React from 'react';

const Instagram = ({ width='30', className='', height='30', viewBox='0 0 30 30' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#instagram_filter0_dd)">
      <path
        d="M23.982 11.292c-.042-.956-.196-1.614-.418-2.184a4.393 4.393 0 00-1.04-1.596 4.43 4.43 0 00-1.594-1.037c-.573-.222-1.227-.377-2.183-.419-.964-.045-1.27-.056-3.714-.056s-2.75.01-3.71.053c-.956.042-1.613.197-2.183.418a4.392 4.392 0 00-1.596 1.041c-.457.45-.813.995-1.038 1.593-.221.573-.376 1.227-.418 2.184-.046.963-.056 1.269-.056 3.713s.01 2.75.052 3.71c.043.956.197 1.613.419 2.183a4.439 4.439 0 001.04 1.596c.45.458.996.813 1.594 1.038.573.221 1.227.376 2.183.418.96.043 1.266.053 3.71.053s2.75-.01 3.71-.053c.956-.042 1.614-.197 2.183-.418a4.604 4.604 0 002.634-2.634c.221-.573.376-1.227.418-2.183.042-.96.053-1.266.053-3.71s-.003-2.75-.046-3.71zm-1.62 7.35c-.04.878-.187 1.353-.31 1.67a2.986 2.986 0 01-1.709 1.708c-.317.123-.795.271-1.67.31-.95.042-1.234.052-3.636.052s-2.69-.01-3.636-.052c-.88-.039-1.354-.187-1.67-.31a2.77 2.77 0 01-1.034-.671 2.799 2.799 0 01-.672-1.034c-.123-.317-.27-.795-.309-1.67-.042-.95-.053-1.235-.053-3.636 0-2.402.01-2.69.053-3.636.039-.88.186-1.354.31-1.67.144-.39.372-.746.675-1.034a2.794 2.794 0 011.033-.672c.317-.123.795-.27 1.67-.31.95-.041 1.235-.052 3.636-.052 2.406 0 2.69.01 3.636.053.88.039 1.354.186 1.67.31.39.143.746.372 1.034.67.3.293.528.644.672 1.035.123.316.27.794.31 1.67.042.95.052 1.234.052 3.636 0 2.401-.01 2.683-.053 3.632z"
        fill="#fff"/>
      <path
        d="M15.033 10.378a4.625 4.625 0 000 9.248 4.625 4.625 0 000-9.248zm0 7.623a3 3 0 11.001-6 3 3 0 010 6zM20.92 10.195a1.08 1.08 0 11-2.16 0 1.08 1.08 0 012.16 0z"
        fill="#fff"/>
      <path
        d="M23.982 11.292c-.042-.956-.196-1.614-.418-2.184a4.393 4.393 0 00-1.04-1.596 4.43 4.43 0 00-1.594-1.037c-.573-.222-1.227-.377-2.183-.419-.964-.045-1.27-.056-3.714-.056s-2.75.01-3.71.053c-.956.042-1.613.197-2.183.418a4.392 4.392 0 00-1.596 1.041c-.457.45-.813.995-1.038 1.593-.221.573-.376 1.227-.418 2.184-.046.963-.056 1.269-.056 3.713s.01 2.75.052 3.71c.043.956.197 1.613.419 2.183a4.439 4.439 0 001.04 1.596c.45.458.996.813 1.594 1.038.573.221 1.227.376 2.183.418.96.043 1.266.053 3.71.053s2.75-.01 3.71-.053c.956-.042 1.614-.197 2.183-.418a4.604 4.604 0 002.634-2.634c.221-.573.376-1.227.418-2.183.042-.96.053-1.266.053-3.71s-.003-2.75-.046-3.71zm-1.62 7.35c-.04.878-.187 1.353-.31 1.67a2.986 2.986 0 01-1.709 1.708c-.317.123-.795.271-1.67.31-.95.042-1.234.052-3.636.052s-2.69-.01-3.636-.052c-.88-.039-1.354-.187-1.67-.31a2.77 2.77 0 01-1.034-.671 2.799 2.799 0 01-.672-1.034c-.123-.317-.27-.795-.309-1.67-.042-.95-.053-1.235-.053-3.636 0-2.402.01-2.69.053-3.636.039-.88.186-1.354.31-1.67.144-.39.372-.746.675-1.034a2.794 2.794 0 011.033-.672c.317-.123.795-.27 1.67-.31.95-.041 1.235-.052 3.636-.052 2.406 0 2.69.01 3.636.053.88.039 1.354.186 1.67.31.39.143.746.372 1.034.67.3.293.528.644.672 1.035.123.316.27.794.31 1.67.042.95.052 1.234.052 3.636 0 2.401-.01 2.683-.053 3.632z"
        stroke="#FFAFAF" strokeWidth=".5" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M15.033 10.378a4.625 4.625 0 000 9.248 4.625 4.625 0 000-9.248zm0 7.623a3 3 0 11.001-6 3 3 0 010 6zM20.92 10.195a1.08 1.08 0 11-2.16 0 1.08 1.08 0 012.16 0z"
        stroke="#FFAFAF" strokeWidth=".5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <filter id="instagram_filter0_dd" x=".782" y=".75" width="28.497" height="28.5" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
        <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
;

export default Instagram;