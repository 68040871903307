import React from 'react';
const Discover = ({ width = '100', className = '', height = '48', viewBox='0 0 100 48' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="#FFF" fillRule="evenodd">
      <g opacity=".98" transform="translate(23 17)">
        <path d="M4.368 6.486c-.523.458-1.197.656-2.267.656h-.445V1.694h.445c1.07 0 1.718.186 2.267.667.573.494.914 1.259.914 2.049 0 .792-.341 1.584-.914 2.076zM2.433.299H0v8.237h2.42c1.285 0 2.213-.295 3.028-.95.967-.778 1.54-1.952 1.54-3.164C6.988 1.99 5.116.299 2.433.299zm5.319 8.237h1.654V.3H7.752zm5.702-5.077c-.994-.357-1.286-.593-1.286-1.036 0-.52.52-.914 1.234-.914.497 0 .904.197 1.338.665l.864-1.097a3.774 3.774 0 00-2.495-.915c-1.5 0-2.646 1.013-2.646 2.358 0 1.137.534 1.717 2.087 2.262.65.221.98.369 1.145.47.331.21.497.505.497.85 0 .669-.548 1.162-1.287 1.162-.789 0-1.424-.383-1.806-1.099L10.03 7.167c.762 1.085 1.678 1.568 2.94 1.568 1.717 0 2.926-1.113 2.926-2.706 0-1.309-.559-1.902-2.443-2.57m2.965.963c0 2.423 1.96 4.3 4.48 4.3.713 0 1.324-.137 2.076-.481V6.35c-.663.642-1.248.9-2 .9-1.666 0-2.85-1.173-2.85-2.841 0-1.58 1.22-2.828 2.775-2.828.788 0 1.387.272 2.075.926V.618C22.248.26 21.649.114 20.937.114c-2.508 0-4.519 1.914-4.519 4.308m19.968 1.41L34.12.3h-1.808l3.603 8.448h.89L40.472.299h-1.794l-2.292 5.533m4.838 2.704h4.696V7.142h-3.041V4.917h2.924V3.522H42.88V1.694h3.04V.3h-4.695v8.237M49.15 4.09h-.481V1.596h.508c1.033 0 1.592.42 1.592 1.222 0 .827-.56 1.274-1.619 1.274zm3.322-1.36c0-1.543-1.093-2.43-3.003-2.43h-2.456v8.236h1.656v-3.31h.216l2.29 3.31h2.036l-2.674-3.47c1.249-.247 1.935-1.075 1.935-2.336zm-37.636 7.971l2.215 2.252v-2.134h.316v2.88l-2.214-2.247v2.123h-.317V10.7"/>
        <path d="M14.866 10.773c.098.098 2.215 2.252 2.215 2.252v-2.179h.256v2.782l-2.213-2.249v2.167h-.258v-2.773zm-.06-.072v2.903h.376v-2.081l2.214 2.247v-2.98h-.375v2.092l-2.214-2.253v.072zm4.094.118h1.472v.284h-1.156v.82h1.122v.285h-1.122v1.082h1.156v.285h-1.471v-2.756m3.705 2.756h-.317v-2.472h-.684v-.284h1.694v.284h-.693v2.472m2.809-.691l.92-2.209.918 2.21.745-2.066h.344l-1.09 2.898-.918-2.22-.92 2.22-1.086-2.898h.342l.745 2.065"/>
        <path d="M25.442 12.894s.84-2.018.892-2.14c.052.122.92 2.211.92 2.211l.765-2.119h.28c-.027.074-.997 2.658-1.048 2.79l-.917-2.215-.918 2.216c-.05-.133-1.018-2.717-1.047-2.79h.28l.764 2.118.03-.07zm.865-2.229s-.836 2.005-.89 2.138l-.727-2.013h-.405l1.13 3.009.92-2.224c.05.126.918 2.224.918 2.224l1.13-3.01h-.405l-.727 2.014-.917-2.204-.027.066zm3.48 1.531c0 .621.52 1.14 1.165 1.14.644 0 1.162-.519 1.162-1.14a1.15 1.15 0 00-1.162-1.14 1.15 1.15 0 00-1.165 1.14zm2.645.004c0 .793-.672 1.422-1.48 1.422-.811 0-1.48-.63-1.48-1.422 0-.79.669-1.429 1.48-1.429.808 0 1.48.64 1.48 1.429z"/>
        <path d="M29.5 12.2c0-.773.65-1.4 1.452-1.4.798 0 1.451.627 1.451 1.4 0 .769-.653 1.393-1.451 1.393-.801 0-1.452-.624-1.452-1.393zm-.058 0c0 .8.677 1.45 1.51 1.45.832 0 1.51-.65 1.51-1.45 0-.805-.678-1.457-1.51-1.457-.833 0-1.51.652-1.51 1.457z"/>
        <path d="M29.817 12.196c0-.612.508-1.111 1.135-1.111.626 0 1.132.499 1.132 1.111 0 .613-.506 1.113-1.132 1.113-.627 0-1.135-.5-1.135-1.113zm-.06 0c0 .647.537 1.17 1.195 1.17a1.18 1.18 0 001.191-1.17 1.18 1.18 0 00-1.191-1.168c-.658 0-1.195.524-1.195 1.168zm4.423-.102h.118c.427 0 .86-.065.86-.493 0-.452-.41-.498-.864-.498h-.113v.991zm0 1.48h-.377V10.82h.491c.644 0 1.242.14 1.242.76 0 .424-.326.724-.85.76l1.08 1.236h-.464l-1.025-1.21h-.096v1.21zm4.131-2.755h.425l-1.332 1.284 1.377 1.472h-.437L37.18 12.31l-.079.075v1.19h-.316v-2.756h.316v1.18l1.21-1.18"/>
        <path d="M38.664 10.846c-.095.093-1.302 1.255-1.302 1.255l1.353 1.445h-.37c0-.015-1.143-1.255-1.143-1.255l-.02-.024-.102.099-.008 1.18h-.257v-2.7h.257v1.223l1.25-1.223h.342zm.072-.056H38.3s-1.076 1.048-1.167 1.14v-1.14h-.376v2.814h.376V12.39a.229.229 0 00.046-.039l1.145 1.243.458.01h.068s-1.368-1.462-1.407-1.5l1.365-1.314h-.07z"/>
        <ellipse cx="28.252" cy="4.447" rx="4.582" ry="4.447"/>
      </g>
    </g>
  </svg>;

  export default Discover;