import React from 'react';



const TopSearch = ({ width = '25', className = '', height = '27', viewBox = "1.5 0 25 27" }) =>
  <svg
    width={width}
    height={height}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    transform="translate(0,0)"
    viewBox={viewBox}
  >
    <g id="evt6iu257zf2_to" transform="translate(6.262051,7.310052)">
      <g id="evt6iu257zf2_ts" transform="scale(1,1)">
        <g id="evt6iu257zf2" transform="translate(0,0)">
          <circle
            id="evt6iu257zf3"
            r="7"
            transform="matrix(1 0 0 1 7 7)"
            fill="none"
            fillRule="evenodd"
            stroke="rgb(241,191,41)"
            strokeWidth="1.500000"
          />
          <circle
            id="evt6iu257zf4"
            r="7"
            transform="matrix(0.75303970439326 0.65797507825700 -0.65797507825700 0.75303970439326 7 7)"
            fill="none"
            fillRule="evenodd"
            stroke="rgb(255,86,86)"
            strokeWidth="1.500000"
            strokeDashoffset="43.980000"
            strokeDasharray="43.980000"
          />
          <g
            id="evt6iu257zf5_tr"
            transform="translate(7,7) rotate(359.150000)"
          >
            <circle
              id="evt6iu257zf5"
              r="7"
              transform="translate(0,0)"
              fill="none"
              fillRule="evenodd"
              stroke="rgb(241,191,41)"
              strokeWidth="1.500000"
              strokeDashoffset="43.980000"
              strokeDasharray="43.980000"
            />
          </g>
          <line
            id="evt6iu257zf6"
            x1="13.500000"
            y1="13.662000"
            x2="17.353000"
            y2="17.257000"
            fill="none"
            fillRule="evenodd"
            stroke="rgb(241,191,41)"
            strokeWidth="1.500000"
            strokeLinecap="round"
          />
          <path
            id="evt6iu257zf7"
            d="M17.353000,17.257000L13.500000,13.662000"
            fill="none"
            fillRule="evenodd"
            stroke="rgb(255,86,86)"
            strokeWidth="1.500000"
            strokeLinecap="round"
            strokeDashoffset="5.270000"
            strokeDasharray="5.270000"
          />
          <circle
            id="evt6iu257zf8"
            r="27.500000"
            transform="matrix(0.11460748232955 0.12321785664343 -0.12321785664343 0.11460748232955 7.02182189995250 7.00000000131273)"
            opacity="0"
            fill="rgb(84,131,204)"
            fillOpacity="0"
            stroke="rgb(241,191,41)"
            strokeWidth="9"
            strokeDashoffset="172.790000"
            strokeDasharray="20"
          />
        </g>
      </g>
    </g>
  </svg>;

export default TopSearch;