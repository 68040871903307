import React from 'react';

const Pinterest = ({ width='28', className='', height='32', viewBox='0 0 28 32' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#pinterest_filter0_dd)">
      <path
        d="M14.272 6c-5.483 0-8.397 3.513-8.397 7.343 0 1.776.992 3.992 2.582 4.695.453.204.393-.046.783-1.537a.354.354 0 00-.085-.348c-2.272-2.627-.443-8.029 4.793-8.029 7.577 0 6.161 10.485 1.318 10.485-1.248 0-2.178-.98-1.884-2.192.356-1.445 1.055-2.997 1.055-4.038 0-2.623-3.909-2.234-3.909 1.242 0 1.074.38 1.799.38 1.799s-1.257 5.08-1.49 6.03c-.395 1.606.053 4.207.092 4.43.024.124.162.163.24.062.124-.163 1.644-2.331 2.07-3.899l.79-2.887c.42.757 1.628 1.39 2.916 1.39 3.83 0 6.598-3.367 6.598-7.544C22.111 8.997 18.684 6 14.272 6z"
        fill="#fff"/>
      <path
        d="M14.272 6c-5.483 0-8.397 3.513-8.397 7.343 0 1.776.992 3.992 2.582 4.695.453.204.393-.046.783-1.537a.354.354 0 00-.085-.348c-2.272-2.627-.443-8.029 4.793-8.029 7.577 0 6.161 10.485 1.318 10.485-1.248 0-2.178-.98-1.884-2.192.356-1.445 1.055-2.997 1.055-4.038 0-2.623-3.909-2.234-3.909 1.242 0 1.074.38 1.799.38 1.799s-1.257 5.08-1.49 6.03c-.395 1.606.053 4.207.092 4.43.024.124.162.163.24.062.124-.163 1.644-2.331 2.07-3.899l.79-2.887c.42.757 1.628 1.39 2.916 1.39 3.83 0 6.598-3.367 6.598-7.544C22.111 8.997 18.684 6 14.272 6v0z"
        stroke="#FFAFAF" strokeWidth=".5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <filter id="pinterest_filter0_dd" x=".625" y=".75" width="26.749" height="30.5" filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="2.5"/>
        <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
        <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
;

export default Pinterest;